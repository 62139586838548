var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _CsrfManager_instances, _CsrfManager_token, _CsrfManager_showNotice;
class CsrfManager {
    constructor(token) {
        _CsrfManager_instances.add(this);
        _CsrfManager_token.set(this, void 0);
        __classPrivateFieldSet(this, _CsrfManager_token, token, "f");
    }
    markExpired() {
        if (this.expired) {
            return;
        }
        __classPrivateFieldGet(this, _CsrfManager_instances, "m", _CsrfManager_showNotice).call(this);
        __classPrivateFieldSet(this, _CsrfManager_token, null, "f");
    }
    get expired() {
        return __classPrivateFieldGet(this, _CsrfManager_token, "f") === null;
    }
    get token() {
        return __classPrivateFieldGet(this, _CsrfManager_token, "f");
    }
}
_CsrfManager_token = new WeakMap(), _CsrfManager_instances = new WeakSet(), _CsrfManager_showNotice = function _CsrfManager_showNotice() {
    Promise.all([
        import(/* webpackChunkName: "csrf_expiration_warning" */ '@templates/csrf_expiration_warning.html'),
        import(/* webpackChunkName: "html_parser" */ '@root/javascript/util/html/parser'),
    ]).then(([{ default: csrfFooterWarning }, { default: parseHtml }]) => {
        var _a, _b;
        const html = parseHtml(csrfFooterWarning);
        // V1 layout
        (_a = document.querySelector('.site-header')) === null || _a === void 0 ? void 0 : _a.appendChild(html);
        // V2 layout
        (_b = document.querySelector('.lc-navbar')) === null || _b === void 0 ? void 0 : _b.after(html);
    });
};
export default new CsrfManager(document.querySelector('meta[name=csrf-token]').getAttribute('content'));
