var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _instances, _cache, _renderer;
import parseHtml from '@root/javascript/util/html/parser';
export default class {
    constructor() {
        _instances.add(this);
        _cache.set(this, {});
    }
    async get(key, template, helpers) {
        const renderer = await __classPrivateFieldGet(this, _instances, "m", _renderer).call(this);
        const cache = __classPrivateFieldGet(this, _cache, "f");
        if (!cache[key]) {
            if (helpers) {
                renderer.registerHelper(helpers);
            }
            cache[key] = renderer.compile(template);
        }
        return cache[key];
    }
    async getCompiled(key, template, options) {
        return this.get(key, template).then((delegate) => parseHtml(delegate(options)));
    }
    async getParsed(key, template, options) {
        return this.get(key, template).then((delegate) => delegate(options));
    }
}
_cache = new WeakMap(), _instances = new WeakSet(), _renderer = async function _renderer() {
    const { default: Handlebars } = await import(/* webpackChunkName: "configured_handlebars" */ './configured');
    return Handlebars;
};
