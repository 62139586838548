import { gql } from '@root/javascript/graphql/client';
var LibraryStatus;
(function (LibraryStatus) {
    LibraryStatus["Completed"] = "completed";
    LibraryStatus["Rewatching"] = "rewatching";
    LibraryStatus["Watching"] = "watching";
    LibraryStatus["Planning"] = "planning";
    LibraryStatus["Considering"] = "considering";
    LibraryStatus["Paused"] = "paused";
    LibraryStatus["Dropped"] = "dropped";
    LibraryStatus["Skipping"] = "skipping";
})(LibraryStatus || (LibraryStatus = {}));
const LOCALSTORAGE_KEY = 'marks';
const STATUSES = new Set([
    LibraryStatus.Completed,
    LibraryStatus.Rewatching,
    LibraryStatus.Watching,
    LibraryStatus.Planning,
    LibraryStatus.Considering,
    LibraryStatus.Paused,
    LibraryStatus.Dropped,
    LibraryStatus.Skipping,
]);
const STATUS_COUNT = STATUSES.size;
const LIBRARY_ENTRY_GQL_FRAGMENT = gql `
  fragment libraryEntryFields on ViewerLibraryEntry {
    animeDatabaseId
    episodesWatched
    status
    rating
    rewatches
    startedAt
    finishedAt
    notes
  }
`;
export { LibraryStatus, LOCALSTORAGE_KEY, STATUS_COUNT, STATUSES, LIBRARY_ENTRY_GQL_FRAGMENT, };
