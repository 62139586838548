import { LibraryStatus } from '@root/javascript/library/constants';
const STATUS_KEY = 'data-library-status';
const statusLabels = {
    [LibraryStatus.Completed]: 'Completed',
    [LibraryStatus.Rewatching]: 'Rewatching',
    [LibraryStatus.Watching]: 'Watching',
    [LibraryStatus.Planning]: 'Planning',
    [LibraryStatus.Considering]: 'Considering',
    [LibraryStatus.Paused]: 'Paused',
    [LibraryStatus.Dropped]: 'Dropped',
    [LibraryStatus.Skipping]: 'Skipping',
};
class UserLibraryUtils {
    static setLibraryStatusAttribute(element, status) {
        element.setAttribute(STATUS_KEY, status || '');
    }
    static libraryStatusLabel(status) {
        return statusLabels[status];
    }
}
export default UserLibraryUtils;
export const { setLibraryStatusAttribute, libraryStatusLabel, } = UserLibraryUtils;
